import React from "react"

import Layout from "../components/layout"

const Home = ({ location }) => (
  <Layout ogPath={location.pathname}>
    <main />
  </Layout>
)

export default Home;
